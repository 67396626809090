<!--
 * @Author: your name
 * @Date: 2021-03-23 15:43:22
 * @LastEditTime: 2021-03-29 10:37:51
 * @LastEditors: Please set LastEditors
 * @Description: 查看审核
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\Lossreport\components\goodslink.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      :title="title"
      fullscreen
      :visible.sync="showlink"
      width="1000px"
      :close-on-click-modal="false"
    >
      <div class="dingwei">
        <el-form :model="form" inline :disabled="isEdit">
          <el-form-item label="单号：">
            <el-input v-model="form.loss_code" disabled />
          </el-form-item>
          <el-form-item label="提报：">
            <el-input v-model="form.add_name" disabled />
          </el-form-item>
          <el-form-item v-show="form.loss_status == 2" label="审核：">
            <el-input v-model="form.check_name" disabled />
          </el-form-item>
          <!-- </br> -->
          <div></div>
          <el-form-item label="仓库：">
            <el-select v-model="form.depot_id" style="width: 120px">
              <el-option
                v-for="(item, index) in warehouse"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-table v-show="form.loss_status == 2" stripe :data="goods">
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            ></el-table-column>
          </el-table>
          <el-table v-show="form.loss_status == 1" stripe :data="goods">
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            >
              <template
                v-if="list.label == '商品名称'"
                #default="{ $index, row }"
              >
                <goods-search
                  :f-key="row.goods_name"
                  :depot-id="form.depot_id"
                  @add-rows="addRows"
                  @select-goods-all="selectGoods($event, row, $index)"
                ></goods-search>
              </template>
              <template v-else-if="list.label == '生产日期'" #default="{ row }">
                <el-date-picker
                  v-model="row.goods_date"
                  value-format="yyyy-MM-dd"
                  style="width: 140px"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </template>
              <template v-else-if="list.label == '审核数量'" #default="{ row }">
                <el-input v-model="row.read_num" style="width: 80px" />
              </template>
              <template v-else-if="list.label == '单价'" #default="{ row }">
                <el-input v-model="row.goods_price" style="width: 100%" />
              </template>
              <template v-else-if="list.label == '金额'" #default="{ row }">
                <!-- <el-input v-model="row.total_price" style="width: 120px" /> -->
                {{
                  (
                    Number(row.goods_price) * Number(row.apply_read_num) || 0
                  ).toFixed(2)
                }}
              </template>
              <template v-else-if="list.label == '单位'" #default="{ row }">
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 70px"
                  @change="unitChange($event, row)"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.unit_id"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="list.label == '备注'" #default="{ row }">
                <el-input v-model="row.Remarks" style="width: 80px" />
              </template>
              <template v-else #default="{ row }">
                {{ row[list.prop] }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              fixed="right"
              width="120"
            >
              <template #default="{ row, $index }">
                <el-button type="text" @click="handlerAdd(row, $index)">
                  添加
                </el-button>
                <el-button type="text" @click="handlerDelet(row, $index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <el-form-item label="备注：">
            <el-input v-model="form.remark" placeholder="备注" />
          </el-form-item>
          <br />
          <el-form-item label="制单人：">
            <el-input v-model="form.add_name" disabled />
          </el-form-item>
          <el-form-item v-show="form.loss_status == 2" label="审核人：">
            <el-input v-model="form.check_name" disabled />
          </el-form-item>
        </el-form>
        <img
          v-show="form.loss_status == 2"
          src="@/assets/ysh.png"
          class="imgt"
        />
        <img
          v-show="form.loss_status == 1"
          src="@/assets/dsh.png"
          class="imgt"
        />
        <img
          v-show="form.loss_status == 3"
          src="@/assets/yzf.png"
          class="imgt"
        />
      </div>

      <div v-if="!isEdit" slot="footer" class="dialog-footer">
        <el-button
          v-show="form.loss_status == 1"
          type="primary"
          @click="handlerReview"
        >
          审核
        </el-button>
        <el-button v-show="form.loss_status != 1" @click="handlePrint">
          打印
        </el-button>
        <el-button @click="showlink = !showlink">关闭</el-button>
      </div>
      <!--    打印预览组件-->
      <ruilang-display-dialog
        ref="RuilangDisplayDialog"
        bill_type="BS"
        :data_id="loss_id"
        :type="1"
      ></ruilang-display-dialog>
    </el-dialog>
  </div>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { postAction } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    components: {
      GoodsSearch,
      RuilangDisplayDialog,
    },
    data() {
      return {
        isEdit: false,
        showlink: false,
        title: '报损单',
        form: {
          depot_id: '',
          loss_id: '',
          loss_code: '',
          depot_name: '',
          loss_status: '',
          loss_status_txt: '',
          total_amount: '',
          add_name: '',
          check_name: '',
          add_time: '',
          check_time: '',
          remark: '',
        },
        goods: [],
        warehouse: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          info: '/depotAdmin/bill-loss/info',
          Review: '/depotAdmin/bill-loss/check',
        },
        colemd: [
          {
            order: 1,
            label: '品牌',
            align: 'center',
            prop: 'goods_brand',
            width: '80',
          },
          {
            order: 2,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_spec',
            width: '80',
          },
          {
            order: 3,
            label: '单位',
            align: 'center',
            prop: 'goods_unit',
            width: '80',
          },
          {
            order: 3.5,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '150',
          },
          {
            order: 4,
            label: '申请数量',
            align: 'center',
            prop: 'apply_read_num',
            width: '100',
          },
          {
            order: 4.4,
            label: '审核数量',
            align: 'center',
            prop: 'read_num',
            width: '100',
          },
          {
            order: 4.5,
            label: '金额',
            align: 'center',
            prop: 'total_price',
            width: '100',
          },
          {
            order: 5,
            label: '生产日期',
            align: 'center',
            prop: 'goods_date',
            width: '170',
          },
          {
            order: 6,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '120',
          },
        ],
        loss_id: '',
      }
    },

    computed: {},
    watch: {
      showlink(val) {
        if (!val) {
          this.$emit('getlist')
        }
      },
    },
    created() {},
    mounted() {
      this.handlerwarehouse()
    },
    methods: {
      handlerAdd(row, $index) {
        this.goods.splice($index, 0, JSON.parse(JSON.stringify(row)))
      },
      handlerDelet(row, $index) {
        if (this.goods.length > 1) {
          this.goods.splice($index, 1)
        } else {
          this.$message({
            message: '不能删除最后一个',
            type: 'warehouse',
          })
        }
      },
      //审核
      handlerReview() {
        // postAction(this.url.Review,)
        var from = {
          remark: this.form.remark,
          goods: [],
          loss_id: this.loss_id,
        }
        this.goods.forEach((list) => {
          var data = {
            id: list.id,
            goodsId: list.goods_id,
            goodsName: list.goods_name,
            read_num: list.read_num,
            goodsPrice: list.goods_price,
            goodsSpec: list.goods_spec,
            goodsUnit: list.goods_unit,
            unitID: list.unit_id,
            goodsDate: list.goods_date,
            remark: list.remark,
            brandId: list.brand_id,
          }
          from.goods.push(data)
        })
        from.goods = JSON.stringify(from.goods)
        postAction(this.url.Review, from)
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.showlink = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err)
            this.$message({
              message: res.msg,
              type: 'error',
            })
          })
      },
      //详情
      handlerinfo(row) {
        this.loss_id = row.id
        postAction(this.url.info, { loss_id: row.id })
          .then((res) => {
            console.log(res, '')
            this.form = res.data.info
            this.goods = res.data.goods
            console.log(this.form, '')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var data = {
              id: '',
              goods_brand: item.brand_name,
              goods_id: item.goods_id,
              arr_unit: item.arr_unit,
              goods_name: item.goods_name,
              apply_read_num: item.quantity,
              goods_price: item.goods_price,
              goods_spec: item.specs,
              goods_unit: item.unit_name,
              unit_id: item.unit_id,
              goods_date: item.goods_production_date,
              brand_id: item.brand_id,
              remark: '',
            }
            data.arr_unit.forEach((unit, idx) => {
              if (data.unit_id == unit.unit_id) {
                let a = data.arr_unit[idx].before_price
                if (a) {
                  data.goods_price = data.arr_unit[idx].before_price
                } else {
                  data.goods_price = data.arr_unit[idx].cost_price
                }
              }
            })
            console.log(data)
            // item.goodsName = item.goods_name
            this.goods.push(data)
          })
          this.$message.success('添加成功')
        }
      },
      selectGoods(event, row, index) {
        console.log(event, row, '1')
        var data = {
          id: '',
          goods_brand: event.brand_name,
          goods_id: event.goods_id,
          arr_unit: event.arr_unit,
          goods_name: event.goods_name,
          apply_read_num: event.quantity,
          goods_price: event.goods_price,
          goods_spec: event.specs,
          goods_unit: event.unit_name,
          unit_id: event.unit_id,
          goods_date: event.goods_production_date,
          brand_id: event.brand_id,
          remark: '',
        }
        Object.assign(row, data)
        row.arr_unit.forEach((unit, idx) => {
          if (row.unit_id == unit.unit_id) {
            let a = row.arr_unit[idx].before_price
            if (a) {
              row.goods_price = row.arr_unit[idx].before_price
            } else {
              row.goods_price = row.arr_unit[idx].cost_price
            }
          }
        })
      },
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        let id = row.arr_unit.filter((item) => item.id == e)[0].unit_id
        row.goods_price = price
        row.goods_unit = name
        row.unit_id = id
        row.arr_unit.forEach((unit, idx) => {
          if (row.unit_id == unit.unit_id) {
            let a = row.arr_unit[idx].before_price
            if (a) {
              row.goods_price = row.arr_unit[idx].before_price
            } else {
              row.goods_price = row.arr_unit[idx].cost_price
            }
          }
        })
        console.log('单位', row.unit_id)
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dingwei {
    position: relative;
  }
  .imgt {
    position: absolute;
    top: -20px;
    right: 40px;
  }
</style>
