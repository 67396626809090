var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.title,
            fullscreen: "",
            visible: _vm.showlink,
            width: "1000px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showlink = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dingwei" },
            [
              _c(
                "el-form",
                {
                  attrs: { model: _vm.form, inline: "", disabled: _vm.isEdit },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.loss_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "loss_code", $$v)
                          },
                          expression: "form.loss_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提报：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.add_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_name", $$v)
                          },
                          expression: "form.add_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.loss_status == 2,
                          expression: "form.loss_status == 2",
                        },
                      ],
                      attrs: { label: "审核：" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.check_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_name", $$v)
                          },
                          expression: "form.check_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          model: {
                            value: _vm.form.depot_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depot_id", $$v)
                            },
                            expression: "form.depot_id",
                          },
                        },
                        _vm._l(_vm.warehouse, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.loss_status == 2,
                          expression: "form.loss_status == 2",
                        },
                      ],
                      attrs: { stripe: "", data: _vm.goods },
                    },
                    _vm._l(_vm.colemd, function (list, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: list.label,
                          align: list.align,
                          prop: list.prop,
                          width: "",
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.loss_status == 1,
                          expression: "form.loss_status == 1",
                        },
                      ],
                      attrs: { stripe: "", data: _vm.goods },
                    },
                    [
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              list.label == "商品名称"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var $index = ref.$index
                                      var row = ref.row
                                      return [
                                        _c("goods-search", {
                                          attrs: {
                                            "f-key": row.goods_name,
                                            "depot-id": _vm.form.depot_id,
                                          },
                                          on: {
                                            "add-rows": _vm.addRows,
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "生产日期"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "140px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            type: "date",
                                            placeholder: "选择日期",
                                          },
                                          model: {
                                            value: row.goods_date,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_date", $$v)
                                            },
                                            expression: "row.goods_date",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "审核数量"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value: row.read_num,
                                            callback: function ($$v) {
                                              _vm.$set(row, "read_num", $$v)
                                            },
                                            expression: "row.read_num",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "单价"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          model: {
                                            value: row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_price", $$v)
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "金额"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                Number(row.goods_price) *
                                                  Number(row.apply_read_num) ||
                                                0
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  }
                                : list.label == "单位"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "70px" },
                                            attrs: { placeholder: "选择单位" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(row.arr_unit, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.unit_name,
                                                value: item.unit_id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : list.label == "备注"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value: row.Remarks,
                                            callback: function ($$v) {
                                              _vm.$set(row, "Remarks", $$v)
                                            },
                                            expression: "row.Remarks",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " + _vm._s(row[list.prop]) + " "
                                        ),
                                      ]
                                    },
                                  },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          fixed: "right",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerAdd(row, $index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerDelet(row, $index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "备注" },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "制单人：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.add_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_name", $$v)
                          },
                          expression: "form.add_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.loss_status == 2,
                          expression: "form.loss_status == 2",
                        },
                      ],
                      attrs: { label: "审核人：" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.check_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_name", $$v)
                          },
                          expression: "form.check_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.loss_status == 2,
                    expression: "form.loss_status == 2",
                  },
                ],
                staticClass: "imgt",
                attrs: { src: require("@/assets/ysh.png") },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.loss_status == 1,
                    expression: "form.loss_status == 1",
                  },
                ],
                staticClass: "imgt",
                attrs: { src: require("@/assets/dsh.png") },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.loss_status == 3,
                    expression: "form.loss_status == 3",
                  },
                ],
                staticClass: "imgt",
                attrs: { src: require("@/assets/yzf.png") },
              }),
            ],
            1
          ),
          !_vm.isEdit
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.loss_status == 1,
                          expression: "form.loss_status == 1",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerReview },
                    },
                    [_vm._v(" 审核 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.loss_status != 1,
                          expression: "form.loss_status != 1",
                        },
                      ],
                      on: { click: _vm.handlePrint },
                    },
                    [_vm._v(" 打印 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showlink = !_vm.showlink
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ruilang-display-dialog", {
            ref: "RuilangDisplayDialog",
            attrs: { bill_type: "BS", data_id: _vm.loss_id, type: 1 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }